.filters {
  // width: 1100px;
  overflow-x: auto;
}

.gradientBefore::before {
  content: "";
  position: absolute;
  top: 0;
  width: 10px;
  height: 92%;
  background: linear-gradient(90deg, #ffffff1f, rgba(255, 255, 255, 0.001));
  /* transparent keyword is broken in Safari */
}

.filters.after::after {
  content: "";
  position: absolute;
  right: 90px; // This value will varient
  top: 0;
  width: 10px;
  height: 93%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.001), #ffffff1f);
  /* transparent keyword is broken in Safari */
}

.filters::-webkit-scrollbar {
  -webkit-appearance: none;
}

.filters::-webkit-scrollbar:horizontal {
  height: 3px;
}

.filters::-webkit-scrollbar-thumb {
  border-radius: 4px;
  // border: solid 2px #223548;
  /* should match background, can't be transparent */
  background-color: #c8d6e5;
}

.filters::-webkit-scrollbar-track {
  background-color: #70a3d6;
  border-radius: 8px;
}

#bodyContent {
  height: 750px;
  width: 1200px;
  background-color: #f1f1f1;
  padding: 24px;
  margin-right: 24px;
}